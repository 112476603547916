<template>
    <div id="chart">
        <apexchart type="bar" height="400" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'

    export default {
        name: "basicChart",
        components: {
            apexchart: VueApexCharts
        },
        props: {
            series: {
                type: Array,
                required: true,
                default: function () {
                    return [{
                        name: 'Completed task',
                        data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 45, 77, 85]
                    }, {
                        name: 'Pending Task',
                        data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 75, 88, 95]
                    }, {
                        name: 'Cancelled Task',
                        data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 44, 59, 30]
                    }]
                }
            }
        },
        data: () => ({

            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                },
                yaxis: {
                    title: {
                        text: 'No of tasks'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " Tasks"
                        }
                    }
                }
            },

        })
    }
</script>

<style scoped>

</style>