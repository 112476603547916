import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {EventBus} from "@/helper/EventBus";

import './app.css'

Vue.config.productionTip = false
Vue.use(require('vue-moment'));
Vue.prototype.$eventBus = EventBus;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
