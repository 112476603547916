import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer:false,
    token: localStorage.getItem('token') || '',
    user_role: localStorage.getItem('user_role') || 0,
    username: localStorage.getItem('username') || '',
  },
  getters: {
    isDrawerOpen:state => state.drawer,
    isLoggedIn: state => !!state.token,
    userRole:state => state.user_role,
    getUsername:state => state.username
  },
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
    auth_success(state, {token,user_role,username}) {
      state.token = token
      state.user_role = user_role
      state.username = username
      localStorage.setItem('token', token)
      localStorage.setItem('user_role', user_role)
      localStorage.setItem('username', username)
    },
    async logout(state) {
      state.token = ''
      state.user_role=''
      state.username=''
      localStorage.removeItem('token')
      localStorage.removeItem('user_role')
      localStorage.removeItem('username')
      await router.push({path: '/login'})
    },
  },
  actions: {
    login({commit}, {token,user_role,username}) {
      commit('auth_success', {token,user_role,username})
    },
    logout({commit}) {
      commit('logout')
    },
  },
  modules: {
  }
})
