<template>
  <div class="home">
    <v-card flat>
      <v-card-title class="orange lighten-5 my-5 py-8">
        <v-row>
          Dashboard
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="3">
            <v-select outlined dense rounded label="Month" :items="getMonth" :item-text="'name'"
                      :item-value="'value'"
                      v-model="month"
                      @change="getMonthData"
            ></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
                    outlined
                    dense
                    v-model="year"
                    label="Year"
                    class="centered-input"
                    rounded
                    readonly
                    prepend-inner-icon="mdi-minus"
                    append-icon="mdi-plus"
                    @click:prepend-inner="decrement"
                    @click:append="increment"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" lg="3">
            <v-card height="150" rounded="xxl" color="indigo lighten-4">
              <v-card-title align="center">
                <v-row align="center" justify="center" class="my-5">
                  <h3>Total No of Tasks
                    <v-icon>mdi-chevron-double-right</v-icon>
                  </h3>
                </v-row>

              </v-card-title>
              <v-card-text>
                <v-row justify="center" align="center">
                  <h2>{{widget_data.total }}</h2>
                </v-row>
              </v-card-text>

            </v-card>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <v-card height="150" rounded="xxl" color="amber lighten-4">
              <v-card-title align="center">
                <v-row align="center" justify="center" class="my-5">
                  <h3>No of Pending Tasks
                    <v-icon>mdi-chevron-double-right</v-icon>
                  </h3>
                </v-row>

              </v-card-title>
              <v-card-text>
                <v-row justify="center" align="center">
                  <h2>{{widget_data.pending}}</h2>
                </v-row>
              </v-card-text>

            </v-card>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <v-card height="150" rounded="xxl" color="green lighten-4">
              <v-card-title align="center">
                <v-row align="center" justify="center" class="my-5">
                  <h3>No of Completed Tasks
                    <v-icon>mdi-chevron-double-right</v-icon>
                  </h3>
                </v-row>

              </v-card-title>
              <v-card-text>
                <v-row justify="center" align="center">
                  <h2>{{widget_data.complete}}</h2>
                </v-row>
              </v-card-text>

            </v-card>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <v-card height="150" rounded="xxl" color="deep-orange lighten-4">
              <v-card-title align="center">
                <v-row align="center" justify="center" class="my-5">
                  <h3>No of Cancelled Tasks
                    <v-icon>mdi-chevron-double-right</v-icon>
                  </h3>
                </v-row>

              </v-card-title>
              <v-card-text>
                <v-row justify="center" align="center">
                  <h2>{{widget_data.cancel}}</h2>
                </v-row>
              </v-card-text>

            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <basic-chart :series="chartData"></basic-chart>

          </v-col>

          <!--            <v-col cols="12" md="6">-->
          <!--              <timeline-chart></timeline-chart>-->
          <!--            </v-col>-->
        </v-row>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>

  import BasicChart from "@/components/basicChart";

  export default {
    name: 'HomeView',
    components: {BasicChart},


    data: () => ({
      current_date: new Date().toLocaleString('en-GB', {day: '2-digit', month: '2-digit', year: 'numeric'}),
      month: (new Date()).getMonth() + 1,
      year: (new Date()).getFullYear(),

      chartData: [{
        name: 'Completed task',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }, {
        name: 'Pending Task',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }, {
        name: 'Cancelled Task',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }],
      total_task:[],
      widget_data: {
        total: 0,
        pending: 0,
        complete: 0,
        cancel: 0
      }

    }),
    computed: {
      getMonth() {

        const data = [];
        const d = new Date();
        const y = d.getFullYear();
        for (let i = 0; i < 12; i++) {
          const month = new Date(y, i);
          const monthValue = month.getMonth();
          const monthName = month.toLocaleString('default', {month: 'long'});
          // const year = month.getFullYear();
          data.push({value: monthValue + 1, name: monthName});
        }

        return data
      },

    },
    watch: {
      year() {
        this.getChart()
      },
    },
    mounted() {
      this.getChart()
    },
    methods: {
      decrement() {
        this.year--
      },
      increment() {
        this.year++
      },
      async getChart() {
        try {
          const params = {
            year: this.year,
            month: this.month
          }
          const r = await window.axios.get('user/board', {params: params})

          const {total,complete, pending, cancel} = r.data
          // this.$set(this.chartData, 0, {
          //     name: 'Total',
          //     data: total
          // })
          this.total_task=total
          this.$set(this.chartData, 0, {
            name: 'Completed',
            data: complete
          })
          this.$set(this.chartData, 1, {
            name: 'Pending',
            data: pending
          })
          this.$set(this.chartData, 2, {
            name: 'Cancelled',
            data: cancel
          })
          this.widget_data = {
            total: this.total_task[this.month-1],
            complete: this.chartData[0].data[this.month-1],
            pending: this.chartData[1].data[this.month-1],
            cancel:this.chartData[2].data[this.month-1]
          }


        } catch (e) {
          this.$eventBus.$emit('showMsg', {
            message: 'Error ' + e,
            color: 'error'
          })
        }
      },
      async getMonthData() {
        try {
          // console.log(this.month)
          // console.log(this.total_task)
          console.log(this.total_task[this.month-1])

          this.widget_data = {
            total: this.total_task[this.month-1],
            complete: this.chartData[0].data[this.month-1],
            pending: this.chartData[1].data[this.month-1],
            cancel:this.chartData[2].data[this.month-1]
          }
        } catch (e) {
          this.$eventBus.$emit('showMsg', {
            message: 'Error ' + e,
            color: 'error'
          })
        }
      }

    }


  }
</script>
<style scoped>
  >>>
  .centered-input input {
    text-align: center
  }
</style>