import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import userLayout from "@/views/userLayout";
import adminLayout from "@/views/adminLayout";
import store from "@/store"
import notfoundComponent from "@/views/notfoundComponent";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: 'login'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import( '@/views/LoginView')
    },

    //region user
    {
        path: '/access-denied',
        name: 'access-denied',
        meta: {layout:userLayout,auth:true,role:10},
        component: () => import( '@/views/accessDenied')
    },
    {
        path: '/home',
        name: 'user-home',
        meta: {layout: userLayout,auth:true,role:10},
        component: HomeView
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {layout: userLayout,auth:true,role:10},
        component: () => import( '@/views/users/profileView')
    },
    {
        path: '/report',
        name: 'report',
        meta: {layout: userLayout,auth:true,role:10},
        component: () => import( '@/views/report/taskReport')
    },
    {
        path: '/my-task',
        name: 'my-task',
        meta: {layout: userLayout,auth:true,role:10},
        component: () => import( '@/views/users/task/myTask')
    },
    {
        path: '/my-task/create',
        name: 'my-task-create',
        meta: {layout: userLayout,auth:true,role:10},
        component: () => import( '@/views/users/task/createTask')
    },
    {
        path: '/my-task/view/:id',
        name: 'my-task-detail',
        meta: {layout: userLayout,auth:true,role:10},
        component: () => import( '@/views/users/task/taskView')
    },
    {
        path: '/my-task/update/:id',
        name: 'my-task-update',
        meta: {layout: userLayout,auth:true,role:10},
        component: () => import( '@/views/users/task/updateTask')
    },

    //endregion user

    //region admin
    {
        path: '/admin/dashboard',
        name: 'admin-dashboard',
        meta: {layout: adminLayout,auth:true,role:20},
        component: () => import( '@/views/admin/dashBoard')
    },
    {
        path: '/admin/task',
        name: 'task',
        meta: {layout: adminLayout,auth:true,role:20},
        component: () => import( '@/views/admin/task/listTask')
    },
    {
        path: '/admin/task/create',
        name: 'create-task',
        meta: {layout: adminLayout,auth:true,role:20},
        component: () => import( '@/views/admin/task/createTask')
    },
    {
        path: '/admin/task/view/:id',
        name: 'task-detail',
        meta: {layout: adminLayout,auth:true,role:20},
        component: () => import( '@/views/admin/task/taskView')
    },
    {
        path: '/admin/task/update/:id',
        name: 'task-update',
        meta: {layout: adminLayout,auth:true,role:20},
        component: () => import( '@/views/admin/task/updateTask')
    },
    {
        path: '/admin/task/transfer/:id',
        name: 'task-transfer',
        meta: {layout: adminLayout,auth:true,role:20},
        component: () => import( '@/views/admin/task/transferTask')
    },
    {
        path: '/admin/position',
        name: 'position',
        meta: {layout: adminLayout,auth:true,role:20},
        component: () => import( '@/views/admin/position/positionView')
    },
    {
        path: '/admin/department',
        name: 'department',
        meta: {layout: adminLayout,auth:true,role:20},
        component: () => import( '@/views/admin/department/departmentView')
    },
    {
        path: '/admin/company',
        name: 'company',
        meta: {layout: adminLayout,auth:true,role:20},
        component: () => import( '@/views/admin/company/companyView')
    },
    {
        path: '/admin/employee',
        name: 'employee',
        meta: {layout: adminLayout,auth:true,role:20},
        component: () => import( '@/views/admin/employee/userList')
    },
    {
        path: '/admin/employee/create',
        name: 'employee-create',
        meta: {layout: adminLayout,auth:true,role:20},
        component: () => import( '@/views/admin/employee/userCreate')
    },
    {
        path: '/admin/employee/view/:id',
        name: 'employee-detail',
        meta: {layout: adminLayout,auth:true,role:20},
        component: () => import( '@/views/admin/employee/userDetail')
    },
    {
        path: '/admin/employee/update/:id',
        name: 'employee-update',
        meta: {layout: adminLayout,auth:true,role:20},
        component: () => import( '@/views/admin/employee/userUpdate')
    },
    {
        path: '/admin/report',
        name: 'admin-report',
        meta: {layout: adminLayout,auth:true,role:20},
        component: () => import( '@/views/admin/report/taskReport')
    },
    //endregion admin

    {
        path: '*',
        name:'not-found',
        component:notfoundComponent
    }


]



const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        if (store.getters.isLoggedIn) {
            if(store.getters.userRole >= to.meta.role){
                next()
                return
            }

            next('/access-denied')
            return;

        }
        next('/login')
    } else {
        next()
    }
})




export default router
